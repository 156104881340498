@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* colors */
  --bg: #f8f8f8;
  --primary: #eab308;
  /* --primary: #F6E66F; */
  --primary-light: #dbeafe;

  /* fonts */
  --poppins: "Poppins", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}

*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  width: 100vw;
  height: 100vh;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  font-size: 1rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word !important;
  background: var(--bg);
  box-sizing: border-box;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#sidebar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: auto;
}

#sidebar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

::-webkit-scrollbar {
  width: 8px;
  @apply bg-gray-200;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400 border-2 border-gray-300;
}

@layer base {
  body {
    @apply font-poppins text-neutral-800;
  }

  button {
    @apply outline-none;
  }

  button:disabled {
    opacity: 50% !important;
    cursor: not-allowed !important;
  }
}

@layer components {
  .form-group {
    @apply w-full text-sm mb-1.5;
  }

  .form-group label {
    @apply block mb-1;
  }

  .form-group input {
    border-width: 1.4px;
    @apply outline-none bg-white px-4 py-4 rounded-xl mb-0.5 w-full;
  }

  .form-group input[disabled] {
    border-width: 1.4px;
    @apply outline-none bg-gray-200 px-4 py-4 rounded-xl mb-0.5 w-full;
  }

  .form-group input[type="file"] {
    border-width: 1.4px;
    @apply w-full bg-white border outline-none px-4 py-4 rounded-xl mb-0.5;
  }

  .form-group textarea {
    border-width: 1.4px;
    @apply outline-none bg-white px-4 py-4 rounded-xl mb-0.5 w-full;
  }

  .form-group select {
    @apply bg-white px-4 py-4 rounded-xl outline-none w-full;
  }

  .table {
    @apply w-full max-w-full text-center border-collapse table-auto border;
  }

  .table thead {
    @apply w-full bg-neutral-100 shadow rounded font-semibold text-neutral-800 border-b text-base;
  }

  .table thead th {
    @apply p-3 m-4;
  }

  .table tbody {
    @apply text-neutral-900;
  }

  .table tbody tr {
    @apply border-b;
  }

  .table tbody tr td {
    @apply px-2 py-4;
  }
}

@layer utilities {
  .btn-primary {
    @apply bg-primary p-4 text-white rounded-xl font-bold;
  }

  .btn-danger {
    @apply bg-red-500 p-4 text-white rounded-xl font-bold;
  }
  .btn-success {
    @apply bg-green-500 p-4 text-white rounded-xl font-bold;
  }

  .btn-danger {
    @apply bg-red-500 p-4 text-white rounded-xl font-bold;
  }

  .btn-primary-rounded {
    @apply bg-primary rounded-full p-1 text-white;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
/* Button */
.mimibowbow-join-btn {
  background-color: #FFD700; /* Bright Yellow */
  color: #333;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.mimibowbow-join-btn:hover {
  background-color: #FFC107;
}

/* Pre-Join Modal */
.pre-join-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* Ensures highest priority */
}

.pre-join-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 400px;
}

.brand-logo {
  width: 100px;
  margin-bottom: 10px;
}

h2 {
  color: #333;
  font-size: 22px;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 15px 0;
}

.toggle-on {
  background-color: green;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.toggle-off {
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.join-confirm-btn {
  background-color: #FFD700;
  padding: 10px 15px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

/* Call Modal (FULL-SCREEN & Highest Z-Index) */
.call-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999; /* Highest Z-index */
}

.call-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  background: #FFD700;
}

h3 {
  font-size: 20px;
  color: #333;
}

.close-btn {
  background: red;
  color: white;
  font-size: 18px;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

/* Full-Screen iFrame */
.call-iframe {
  width: 100vw;
  height: 100vh;
  border: none;
}
